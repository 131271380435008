<template>
  <el-card class="info_card">
    <div slot="header">
        <el-row type="flex" justify="space-between"
          ><el-col :span="20"><span>用户管理</span></el-col
          ><el-col :span="1.5">
            <el-button
              type="success"
              round
              icon="el-icon-circle-plus-outline"
              @click="Add()"
              :loading="isLoading"
              >添加</el-button
            ></el-col
          ></el-row
        >
      </div>
    <UserSelect ref="UserSelect" />
    <UserAdd ref="UserAdd" @getInfo="getInfo" />
  </el-card>
</template>

<script>
import UserSelect from "./UserSelect/index.vue"
import UserAdd from "./UserAdd/index.vue"
export default {
components:{
  UserSelect,
  UserAdd
},
data(){
  return{
    isLoading:false,
  }
},
methods:{
  Add(){
    this.$refs.UserAdd.handleOpen()
  },
  getInfo(){
    this.$refs.UserSelect.getInfo()
  },
}
}
</script>

<style scoped>


</style>