<template>
  <div>
    <el-table :data="userList" border style="width: 100%">
      <el-table-column prop="account" label="账号" width="180">
      </el-table-column>
      <el-table-column prop="name" label="昵称" width="180"> </el-table-column>
      <el-table-column prop="role" label="角色">
        <template slot-scope="scope">{{
          scope.row.role == 1 ? "管理员" : "普通用户"
        }}</template></el-table-column
      >
      <el-table-column prop="gender" label="性别"
        ><template slot-scope="scope">{{
          scope.row.gender == 1 ? "男" : scope.row.gender == 2 ? "女" : "未知"
        }}</template>
      </el-table-column>
      <el-table-column prop="phone" label="电话号码"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column label="操作" width="150">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <UserEdit ref="UserEdit" :form="form" />
  </div>
</template>

<script>
import UserEdit from "../UserEdit/index.vue"
export default {
  name: "UserSelect",
  components:{
    UserEdit
  },
  data() {
    return {
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      userList: [],
      form:{}
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("user/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
        })
        .then((res) => {
          var { data } = res;
          this.userList = data.user_list;
          this.total = data.total;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //编辑列函数
    handleEdit(index,row){
      this.form = row
      this.$refs.UserEdit.handleOpen();
    },
    //删除列函数
    handleDelete(index, row){
      this.$confirm(
        "此操作将删除用户：" +
          row.account +
          "(" +
          row.name +
          ")  是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$store.dispatch("user/del", {"id":row.id}).then((res) => {
              this.$message.success(res.msg);
              this.getInfo();
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    }
  },
};
</script>

<style>
</style>