<template>
  <el-dialog
    title="编辑用户信息"
    :visible="dialogEditFormVisible"
    width="40rem"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="form.name" placeholder="请输入名称/昵称"></el-input>
      </el-form-item>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="账号"
            :label-width="formLabelWidth"
            prop="account"
          >
            <el-input
              v-model="form.account"
              placeholder="请输入账号"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="密码"
            :label-width="formLabelWidth"
            prop="password"
          >
            <el-input
              v-model="form.password"
              placeholder="请输入密码"
              type="password"
              autocomplete="off"
              show-password
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item label="身份" :label-width="formLabelWidth" prop="role">
            <el-select v-model="form.role" placeholder="请选择用户身份">
              <el-option
                v-for="item in roleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="性别"
            :label-width="formLabelWidth"
            prop="gender"
          >
            <el-radio v-model="form.gender" :label="1">男</el-radio>
            <el-radio v-model="form.gender" :label="2">女</el-radio>
            <el-radio v-model="form.gender" :label="3"
              >未知</el-radio
            ></el-form-item
          ></el-col
        ></el-row
      >
      <el-form-item label="电话号码" :label-width="formLabelWidth" prop="phone">
        <el-input
          v-model="form.phone"
          placeholder="请输入电话号码"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEditCancel()" icon="el-icon-circle-close"
        >取 消</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleEdit()"
        icon="el-icon-circle-check"
        >保 存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "UserEdit",
  props:['form'],
  data() {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      } else {
        if(/^[a-zA-Z0-9]{4,20}$/.test(value)){
          callback();
        }
        return callback(new Error("只允许4~20位的大小写字母或数字"));
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        if(/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{5,}$/.test(value)){
          callback();
        }
        return callback(new Error("长度至少5，必须包含数字和大小写"));
      }
    };
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      } else {
        if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)){
          callback();
        }
        return callback(new Error("请输入正确的邮箱"));
      }
    };
    return {
      dialogEditFormVisible: false,
      isLoading: false,
      formLabelWidth: "5rem",
      //用户身份选项
      roleOptions: [
        {
          value: 1,
          label: "管理员",
        },
        {
          value: 2,
          label: "普通用户",
        },
      ],
      //表单验证规则
      rules: {
        account: [{ validator: checkAccount, trigger: "blur" }],
        password: [{ validator: checkPassword, trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
      },
    };
  },
  methods: {
    handleOpen() {
      this.dialogEditFormVisible = true;
    },
    handleEdit() {
      this.isLoading = true;
      this.$store
        .dispatch("user/edit", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleEditCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    handleEditCancel() {
      this.dialogEditFormVisible = false;
    },
  },
};
</script>

<style>
</style>